<template>
  <div class="chongzhi">
    <headbar :title="$t('DG投入')"></headbar>

    <div class="content">
      <div class="title">{{ $t('币种') }}</div>
      <div class="input-wrap">
        <div class="select-label">DG</div>
      </div>
      <div class="title">{{ $t('金额') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入金额')" />
      </div>
      <div class="jine-list">
        <div class="jine-item" :class="{ 'jine-active': item == num }" v-for="(item, index) in jineList" :key="index"
          @click="num = item">
          {{ item }}
        </div>
      </div>
      <div class="balance">
        <div>{{ $t('钱包余额') }}：{{ balance }}</div>
        <!-- <div class="all-btn" @click="num = balance">{{ $t('全部') }}</div> -->
      </div>
      <div class="m-btn btn" @click="submit">{{ $t('确定') }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "../../assets/js/web3helper.js";
import useClipboard from 'vue-clipboard3';
import CustomComponent from "@/components/CustomComponent";
import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";

export default {
  name: '',
  components: {},
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      balance: ref(0),
      contractInfo: ref(null),
      web3Helper: ref({}),

      jineList: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getSettings()
    this.getDG()
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getSettings() {
      let _this = this
      _this.$request.post(
        "api/InputOrder/GetSettings",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          // console.log(data)
          _this.jineList = data.selectAmount.split(',')
          _this.num = _this.jineList[0]
        }
      )
    },
    getDG() {
      let _this = this
      _this.$request.post(
        "api/InputOrder/GetDG",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          let data = res.data.data
          if (data) {
            _this.balance = data
          }
        }
      )
    },
    submit() {
      let _this = this
      if (this.num == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要投入吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("投入中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/InputOrder/Input",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              jine: _this.num,
              signStr: signStr,
              address: _this.us.address
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.chongzhi {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 10px;
  /* background-color: #1B1B1B; */
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #1B1B1B;
  ;
}

.select-label {
  font-size: 18px;
  font-weight: bold;
  color: #e7ba47;
}

.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #aaaaaa;
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background-color: #FFD585;
}

.line {
  margin-top: 20px;
  height: 5px;
  /* background: url(~@/assets/img/base/fenge.png) no-repeat; */
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.jine-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.jine-item {
  margin: 0 10px 10px 0;
  min-width: 70px;
  padding: 4px 0;
  text-align: center;
  color: #aaaaaa;
  background-color: #1B1B1B;
  border: 1px solid #1B1B1B;
  box-sizing: border-box;
  border-radius: 4px;
}

.jine-active {
  color: #e7ba47;
  border: 1px solid #e7ba47;
}
</style>